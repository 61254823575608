<template>
     <!-- Footer -->
<footer class="text-center text-lg-start text-muted" style="background-color: #422B70; position: absolute; width: 100%;">
  <!-- Section: Social media -->
  <section class="d-flex justify-content-center justify-content-lg-between p-1">
    <!-- Left -->

    <!-- Right -->

  </section>
  <!-- Section: Social media -->

  <!-- Section: Links  -->
  <section class="">
    <div class="container text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3">

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h5 class="text-uppercase fw-bold mb-4 text-white">
            Fique por dentro
          </h5>
          <p>
            <facebook/>
            <a href="#!" class="text-reset">Facebook</a>
          </p>
          <p>
            <instagram />
            <a href="#!" class="text-reset">Instagram</a>
          </p>
          <p>
            <linkedin />
            <a href="#!" class="text-reset">Linkedin</a>
          </p>

        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h5 class="text-uppercase fw-bold mb-4 text-white">
            COMPANY
          </h5>
          <p>
            <a style="cursor:pointer" href="/" class="text-reset">Sobre</a>
          </p>
          <p>
            <!-- privacidade -->
            <a style="cursor:pointer" @click="$router.push({ name: 'Privacidade' })" class="text-reset">Terms & Condition</a>
          </p>
          <p>
            <!-- privacidade 2 -->
            <a style="cursor:pointer" @click="$router.push({ name: 'Privacidade' })" class="text-reset">Privacy</a>
          </p>
          <p>
            <!-- whatsapp -->
            <a style="cursor:pointer" @click="$router.push({ name: 'Privacidade' })" class="text-reset">Risk</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <!-- Content -->

          <p>
            <img src="../../assets/images/Marca.svg" alt="" width="100%">
            <img class="btn-store" style="padding: 2%;" src="../../assets/images/googleplay.svg" alt="">
            <img class="btn-store" style="padding: 2%;" src="../../assets/images/appstore.svg" alt="">
          </p>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4" style="background-color:whitesmoke">
      ©2022 Vulpee. CNPJ 47.390.988/0001-06 | Desenvolvido por
    <a class="text-reset fw-bold pl-1" href="https://shinier.com.br/">Shinier</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
</template>

<script>
import linkedin from '@/components/icons/linkedin'
import facebook from '@/components/icons/facebook'
import instagram from '../icons/instagram.vue'
export default {
  components: { linkedin, facebook, instagram }
}
</script>

<style>

</style>
