<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="50px" viewBox="0 0 26 26"><g><g><g><path d="M19.89,4.64a1.53,1.53,0,1,0,1.52,1.54v0a1.52,1.52,0,0,0-1.52-1.52h0"></path><path class="cls-1" d="M13.11,6.59A6.41,6.41,0,1,0,19.51,13h0a6.41,6.41,0,0,0-6.4-6.41m0,10.51A4.11,4.11,0,1,1,17.21,13v0a4.12,4.12,0,0,1-4.1,4.1"></path><path d="M18.19,26H7.81A7.83,7.83,0,0,1,0,18.19V7.81A7.81,7.81,0,0,1,7.81,0H18.19A7.81,7.81,0,0,1,26,7.81V18.19A7.83,7.83,0,0,1,18.19,26M7.81,2.44A5.38,5.38,0,0,0,2.45,7.81V18.19a5.36,5.36,0,0,0,5.36,5.36H18.19a5.38,5.38,0,0,0,5.37-5.36V7.81a5.39,5.39,0,0,0-5.37-5.37Z"></path></g></g></g></svg>
</template>

<script>
export default {

}
</script>

<style scoped>
path{
  fill: #fff;
}
</style>
