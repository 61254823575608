<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="50px" viewBox="0 0 11 20"><path d="M11 0H8a5 5 0 00-5 4 5 5 0 000 1v2H0v5h3v8h4v-8h3l1-1V8l-1-1H7V5l2-1h2V0"></path></svg>
</template>

<script>
export default {

}
</script>

<style scoped>
path{
  fill: #fff;
}
</style>
