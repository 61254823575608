<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="25px" width="50px" viewBox="0 0 93.06 93.06"><g><g><path d="M11.185,0.08C5.004,0.08,0.001,5.092,0,11.259c0,6.173,5.003,11.184,11.186,11.184c6.166,0,11.176-5.011,11.176-11.184 C22.362,5.091,17.351,0.08,11.185,0.08z"/><rect x="1.538" y="30.926" width="19.287" height="62.054"/><path d="M69.925,29.383c-9.382,0-15.673,5.144-18.248,10.022h-0.258v-8.479H32.921H32.92v62.053h19.27V62.281 c0-8.093,1.541-15.932,11.575-15.932c9.89,0,10.022,9.256,10.022,16.451v30.178H93.06V58.942 C93.06,42.235,89.455,29.383,69.925,29.383z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
</template>

<script>
export default {

}
</script>

<style scoped>
path{
  fill: #fff;
}
rect{
  fill: #fff;
}
</style>
